import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const DockerPage = () => (
  <Layout>
      <Seo title="Docker Hub" />
      <h1>Docker repositories</h1>
      <p>
      Docker Pull Command
      </p>
      <p>
        <code>docker pull vnsp/gatsbyjs</code>
      </p>
      <p>
        Supported tags and respective Dockerfile links: <a href="https://hub.docker.com/u/vnsp">Docker Hub</a>
      </p>
  </Layout>
)

export default DockerPage
